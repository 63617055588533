import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { AppContext } from "../../contexts/AppContext";
// import { ApiUrl } from "../../environment/ApiUrl";
// import axios from "../../utils/axios";
// import { useSnackbar } from "notistack";
// import Razorpay from 'razorpay';
import axios from "axios";
import { Cookies, useCookies } from "react-cookie";
import {
  API_Order,
  token,
  API_CART,
  API_BillingAddress_Userid,
  API_URL,
  API_PAYUPID,
  API_PAYMENTGET,
} from "../config/config";
export default function RazorPay({billing}) {
  const [cookies, setCookie, removeCookie] = useCookies();
  // const [billing, setBilling] = useState();
  useEffect(() => {
    Getpayment();
  }, []);
  const [success, setSuccess] = useState(false);
  const Getpayment = async () => {
    let formData = new FormData();
    formData.append("userid", cookies.customer_id);
    formData.append(
      "manual_reward",
      cookies.manual_reward_points && cookies.rewardpoints == "true"
        ? cookies.manual_reward_points > 0
          ? cookies.manual_reward_points
          : 0
        : 0
    );
    formData.append(
      "reward_points",
      cookies.rewardpoints
        ? cookies.rewardpoints == "true"
          ? true
          : false
        : false
    );
    const configs = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const Result = axios
      .post(API_PAYMENTGET, formData, configs)
      .then((res) => {
        const TotalAmount = res.data.list;
        var options = {
          key: "rzp_live_AJtoVs8KZzHvU2",
          key_secret: "vMl4FI2UlPAgLbPnLEynITpz",
          amount: TotalAmount * 100,
          currency: "INR",
          name: "Babyamore",
          description: "Babyamore" + "Product",
          handler: async function (response) {
            console.log(response, "responseresponseresponseresponse");
            if (
              response.razorpay_payment_id != null &&
              response.razorpay_payment_id != undefined
            ) {
              alert(
                `Payment Successful, payment ID - ${response.razorpay_payment_id}`
              );
              try {
                // setPayMethod(false);
                const dats = {
                  userid: cookies.customer_id,
                  BillingAddress: [billing],
                  ShippingAddress: [billing],
                  reward_points: cookies.rewardpoints
                    ? cookies.rewardpoints == "true"
                      ? true
                      : false
                    : false,
                  manual_reward:
                    cookies.manual_reward_points &&
                    cookies.rewardpoints == "true"
                      ? cookies.manual_reward_points > 0
                        ? cookies.manual_reward_points
                        : 0
                      : 0,
                };
                const configss = {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                };
                const Result = await axios.post(`${API_Order}`, dats, configss);
                if (Result) {
                  const value = Result.data.msg;
                  removeCookie("rewardpoints");
                  removeCookie("rewardpointss");
                  removeCookie("manual_reward_points");
                  window.location.href = `/order-complete/${value}`;
                }
              } catch (error) {
                console.log(error);
              }
            } else {
              alert("Payment failed!");
              // try {
              //     setPayMethod(false);
              //     const dats = {
              //       userid: cookies.customer_id,
              //       BillingAddress: [billing],
              //       ShippingAddress: [billing],
              //       reward_points: cookies.rewardpoints
              //         ? cookies.rewardpoints == "true"
              //           ? true
              //           : false
              //         : false,
              //       manual_reward:
              //         cookies.manual_reward_points && cookies.rewardpoints == "true"
              //           ? cookies.manual_reward_points > 0
              //             ? cookies.manual_reward_points
              //             : 0
              //           : 0,
              //     };
              //     // console.log("datdatda",dats)
              //     const Result = await axios.post(`${API_Order}`, dats, configss);
              //     if (Result) {
              //       const value = Result.data.msg;
              //       removeCookie("rewardpoints");
              //       removeCookie("rewardpointss");
              //       removeCookie("manual_reward_points");
              //       window.location.href = `/order-complete/${value}`;
              //     }
              //   } catch (error) {
              //   }
            }
          },
          prefill: {
            name: "Babyamore",
            email: "babyamore@gmail.com",
            contact: "+919876543210",
          },
          notes: {
            address: "Razorpay Corporate office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        var pay = new window.Razorpay(options);
        pay.open();
      })
      .catch((err) => {
        console.log(err, "errrrrrr");
      });
  };

  return (
    <>
      {!success ? (
        <form>
          <fieldset className="FormGroup">
            <div className="FormRow">
              {/* <CardElement options={CARD_OPTIONS}/> */}
            </div>
          </fieldset>
          {/* <button>Pay</button> */}
        </form>
      ) : (
        <div>
          <h2>Payment Successful</h2>
        </div>
      )}
    </>
  );
  //   return <div ref={paypal} />;
}
