import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { API_URL, token, API_Order } from "../config/config";
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};
const paytm = `${API_URL}/admin/paytmpay/getpaymentdata`;
const Paytmorder = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const { orderid, userid } = useParams();

  const getData = async () => {
    let formData = new FormData();
    formData.append("payment_orderID", orderid);
    formData.append("user_id", userid);
    let result = await axios
      .post(`${paytm}`, formData, config)
      .then(async (res) => {
        if (res.data.list[0].payment_status == 1) {
          let { billing_address, manual_reward, reward_points } =
            res.data.list[0];
          const dats = {
            userid: cookies.customer_id,
            BillingAddress: [JSON.parse(billing_address)],
            ShippingAddress: [JSON.parse(billing_address)],
            reward_points: reward_points
              ? reward_points == "true"
                ? true
                : false
              : false,
            manual_reward:
              manual_reward && reward_points == "true"
                ? manual_reward > 0
                  ? manual_reward
                  : 0
                : 0,
          };
          // console.log("datdatda",dats)
          const Result = await axios
            .post(`${API_Order}`, dats, config)
            .then((res) => {
              if (res) {
                console.log(res.data.msg);
                const value = res.data.msg;
                removeCookie("rewardpoints");
                removeCookie("rewardpointss");
                removeCookie("manual_reward_points");
                window.location.href = `/order-complete/${value}`;
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (orderid && userid) {
      getData();
    }
  }, []);
  return (
    <div>
      <div className="p-5 d-flex align-center justify-content-center">
        <h5 className="text-success">Please Wait...</h5>
      </div>
    </div>
  );
};

export default Paytmorder;
