import React from 'react'
import './navBar.css'

export const TopNav = () => {
    return (
        <div >
            {/* <div className='display-flex'>
                <div>
                    <p>Free Shipping on Orders above Rs 1999</p>
                </div>
                <div className='d-flex'>
                    <p className='mx-3'>FAST DELIVERY</p>
                    <p className='mx-3'>BEST PRICES EVERYDAY </p>
                    <p className='mx-3'>WIDEST CHOICE</p>
                </div>
                <div>
                    <p>Login / Register</p>
                </div>
            </div> */}
        </div>
    )
}
