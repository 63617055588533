import React from "react";
import { ReactBingmaps } from "react-bingmaps";
import { useEffect } from "react";
import { useState } from "react";

const Googlemaps = ({handleLat}) => {
  const [loc, setloc] = useState([
    {
      location: [13.112765353450584, 80.01586151123048],
      option: { color: "red" },
    },
  ]);
  const AddPushPinOnClick = (location) => {
    console.log(location,"kkkkkkkkkkkkkkkkkkkk");
    const lat = location.latitude;
    const long = location.longitude;
    handleLat(lat,long)

    setloc([
      {
        location: [lat, long],
        option: { color: "red" },
      },
    ]);
  };

  useEffect(() => {}, []);
  return (
    <div className="mb-2 mt-2 " style={{height:"300px"}}>
      <ReactBingmaps
        getLocation={{ addHandler: "click", callback: AddPushPinOnClick }}
        pushPins={loc}
        zoom = {5}
        center={[13.067439, 80.237617]}
        bingmapKey="Ap6llfqONwvQPf2A5INRhAZ4hbjeZKG973SDQGR4nPv19M7Ug3ZnBVwPz7TsAn5G"
      ></ReactBingmaps>
    </div>
  );
};

export default Googlemaps;
